<template>
  <b-overlay :show="busy" no-wrap>
    <template #overlay>
      <div class="text-center p-3 bg-primary text-light rounded">
        <feather-icon icon="LoaderIcon" size="30"/>
        <div class="mt-1">
          Please wait...
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import {BOverlay} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
  },
  model: {
    prop: 'busy',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    busy: false,
  },
}
</script>

<style scoped>

</style>
